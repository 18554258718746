/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_UserOnboardingTask {
    display: contents;

    .mx_UserOnboardingTask_number {
        counter-increment: user-onboarding;
        grid-column: 1;
        color: $secondary-content;
        width: 32px;
        height: 32px;
        text-align: center;
        border: 2px solid $quinary-content;
        border-radius: 32px;
        line-height: 32px;
        align-self: center;
        position: relative;

        &::before {
            content: counter(user-onboarding);
        }
    }

    .mx_UserOnboardingTask_content {
        grid-column: 2;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;

        transition: all 500ms;

        .mx_UserOnboardingTask_title {
            font: var(--cpd-font-body-md-medium);
        }

        .mx_UserOnboardingTask_description {
            font-size: $font-12px;
        }
    }

    .mx_UserOnboardingTask_action.mx_AccessibleButton {
        grid-column: 3;
        min-width: 180px;

        @media (max-width: 800px) {
            grid-column: 2;
            margin-top: -16px;
        }
    }

    &.mx_UserOnboardingTask_completed {
        .mx_UserOnboardingTask_number {
            &::before {
                content: "";
                position: absolute;
                inset: -2px;
                background: var(--cpd-color-icon-accent-tertiary);
                border-radius: 32px;

                animation-duration: 300ms;
                animation-fill-mode: both;
                animation-name: mx_UserOnboardingTask_spring;
                will-change: opacity, transform;
            }

            &::after {
                background-color: var(--cpd-color-icon-on-solid-primary);
                content: "";
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 24px;
                width: inherit;
                height: inherit;
                position: absolute;
                left: 0;
                top: 0;
                mask-image: url("@vector-im/compound-design-tokens/icons/check.svg");

                animation-duration: 300ms;
                animation-fill-mode: both;
                animation-name: mx_UserOnboardingTask_spring;
                will-change: opacity, transform;
            }
        }

        .mx_UserOnboardingTask_content {
            opacity: 0.6;
        }
    }
}

@keyframes mx_UserOnboardingTask_spring {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

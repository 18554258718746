/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_UserOnboardingHeader {
    display: flex;
    flex-direction: row;
    padding: $spacing-32;
    border-radius: 16px;
    background: $system;
    gap: $spacing-64;

    animation-delay: 1500ms;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    animation-name: mx_UserOnboardingHeader_slideIn;
    animation-fill-mode: backwards;
    will-change: opacity, transform;

    @media (max-width: 1280px) {
        margin: $spacing-32;
    }

    .mx_UserOnboardingHeader_dot {
        color: $accent;
    }

    .mx_UserOnboardingHeader_content {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        flex-shrink: 1;
        flex-grow: 1;
        min-width: 0;
        gap: $spacing-24;
        margin-right: auto;

        p {
            margin: 0;
        }

        .mx_AccessibleButton {
            margin-top: auto;
            align-self: flex-start;
            padding: $spacing-12 $spacing-24;
        }
    }

    .mx_UserOnboardingHeader_image {
        flex-basis: 30%;
        flex-shrink: 1;
        flex-grow: 1;
        align-self: center;
        height: calc(100% + $spacing-64 + $spacing-64);
        aspect-ratio: 4 / 3;
        object-fit: contain;
        min-width: 0;
        min-height: 0;
        margin-top: -$spacing-64;
        margin-bottom: -$spacing-64;

        animation-delay: 1500ms;
        animation-duration: 300ms;
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
        animation-name: mx_UserOnboardingHeader_slideInLong;
        animation-fill-mode: backwards;
        will-change: opacity, transform;
    }
}

@keyframes mx_UserOnboardingHeader_slideIn {
    0% {
        transform: translate(0, 8px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes mx_UserOnboardingHeader_slideInLong {
    0% {
        transform: translate(0, 32px);
    }
    100% {
        transform: translate(0, 0);
    }
}
